// app/javascript/channels/consumer.ts
// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { Consumer, createConsumer } from "@rails/actioncable";

const consumers = new Map();

function getConsumerFor(cableToken: string | null): Consumer {
  const url = new URL(document.head.querySelector("meta[name='action-cable-url']").content);
  if (cableToken) url.searchParams.set('token', cableToken);

  if (!consumers.has(cableToken)) consumers.set(cableToken, createConsumer(url.toString()));

  return consumers.get(cableToken);
}

export { getConsumerFor };
